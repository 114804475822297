import React from 'react';
import { Link } from "react-router-dom";
import { SiYourtraveldottv } from "react-icons/si";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt,   } from "react-icons/fa";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa";

export default function Footer() {
    return (
        <footer className="bg-white text-center text-neutral-600 lg:text-left border-t border-gray-300">
            {/* Main container */}
            <div className="mx-6 py-10 text-center md:text-left">
                <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
                    {/* Company description section */}
                    <div>
                        <Link to="/" className="flex items-center justify-center lg:justify-start space-x-3 rtl:space-x-reverse">
                            <SiYourtraveldottv className="text-green-800" size={30} />
                            <span className="text-xl font-semibold text-gray-800">Kashmir Charms</span>
                        </Link>
                        <p className="mt-4 text-gray-400">
                            Kashmir Charms is committed to sustainable and eco-friendly practices that minimize our impact on the environment.
                        </p>
                        {/* Social media icons */}
                        <div className="flex justify-center lg:justify-start mt-6 space-x-4">
                        <a href="#" className="text-white bg-green-800 p-2 rounded-full"><FaFacebookF /></a>
                            <a href="#" className="text-white bg-green-800 p-2 rounded-full"><FaTwitter /></a>
                            <a href="#" className="text-white bg-green-800 p-2 rounded-full"><FaInstagram /></a>
                            <a href="#" className="text-white bg-green-800 p-2 rounded-full"><FaLinkedinIn /></a>
                        </div>
                    </div>

                    {/* Useful links section */}
                    <div>
                        <h6 className="mb-4 text-gray-400 text-xl font-semibold uppercase">Useful links</h6>
                        <ul>
                            <li className="mb-4">
                                <a className="text-gray-400 hover:text-green-800" href="#">Home</a>
                            </li>
                            <li className="mb-4">
                                <a className="text-gray-400 hover:text-green-800" href="#">Packages</a>
                            </li>
                            <li className="mb-4">
                                <a className="text-gray-400 hover:text-green-800" href="#">Blog</a>
                            </li>
                            <li>
                                <a className="text-gray-400 hover:text-green-800" href="#">Tours</a>
                            </li>
                        </ul>
                    </div>

                    {/* Contact section */}
                    <div>
                        <h6 className="mb-4 text-gray-400 text-xl font-semibold uppercase">Contact</h6>
                        <ul>
                            <li className="mb-4 flex items-center">
                                <FaMapMarkerAlt className="text-white bg-green-800 p-2 rounded-full mr-3" size={30} />
                                <span className="text-gray-400">Sheikhpora Budgam Near DPS Road</span>
                            </li>
                            <li className="mb-4 flex items-center">
                                <FaPhoneAlt className="text-white bg-green-800 p-2 rounded-full mr-3" size={30} />
                                <span className="text-gray-400">+919419168660</span>
                            </li>
                            <li className="flex items-center">
                                <FaEnvelope className="text-white bg-green-800 p-2 rounded-full mr-3" size={30} />
                                <span className="text-gray-400">kashmircharms@gmail.com</span>
                            </li>
                        </ul>
                    </div>

                    {/* Newsletter section */}
                    <div>
                        <h6 className="mb-4 text-gray-400 text-xl font-semibold uppercase text-center lg:text-left">Subscribe to our newsletter</h6>
                        <form className="flex flex-col lg:flex-row items-center justify-center lg:justify-start">
                            <input type="email" placeholder="Enter your email" className="mb-4 lg:mb-0 lg:mr-4 p-2 w-full lg:w-2/3 border border-gray-300 rounded" />
                            <button className="px-6 py-2 bg-green-500 text-white rounded hover:bg-green-700 w-full lg:w-auto">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>

            {/* Copyright section */}
            <div className="bg-neutral-200 p-6 text-center text-gray-400 text-lg mb-[3rem] md:mb-1 lg:mb-1">
                <span>© 2023 Kashmir Charms Tour And Travels</span><br />
                <span>
                <a href="#" className="text-gray-400 text-sm">Design And Developed By: Hilal Ahmad Rather</a>

                </span>
            </div>
        </footer>
    );
}
